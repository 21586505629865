import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { VscChevronRight } from 'react-icons/vsc';

import SubsectionList from '../SubsectionList';
import HomeLink from '../HomeLink';
import useAuth from '../../hooks/useAuth';

const CompendiumStructure = ({
  structure,
  triggerStructure,
  onSectionClick,
  chosenSectionPath,
}) => {
  const { isAdmin } = useAuth();

  return (
    <nav className="shrink-0 flex flex-col justify-between w-[148px] border-purple-primary border-r-2">
      <ul className="flex flex-col overflow-auto">
        {structure.map(({ name, path, subsections, isOpen }) => {
          const isPublic = subsections.some(({ articles }) =>
            articles.some(({ isPublic }) => isPublic),
          );

          return (
            <li
              className="shrink-0 flex flex-col mt-2 overflow-hidden"
              key={name}
              data-testid={`${name}-section`}
            >
              <div
                className={cn('flex items-center gap-x-1', {
                  'bg-purple-100': `${path}/root` === chosenSectionPath,
                  'opacity-70': !isPublic && !isAdmin,
                })}
              >
                {subsections.length > 1 && (
                  <motion.button
                    animate={{ rotate: isOpen ? 90 : 0 }}
                    onClick={() => triggerStructure(path)}
                  >
                    <VscChevronRight color="black" />
                  </motion.button>
                )}
                <span
                  className={cn(
                    { 'ml-5': subsections.length <= 1 },
                    'grow font-bold cursor-pointer',
                  )}
                  onClick={() => onSectionClick(`${path}/root`)}
                >
                  {name}
                </span>
              </div>
              <SubsectionList
                isOpen={isOpen}
                subsections={subsections.filter(({ name }) => name !== 'root')}
                onSectionClick={onSectionClick}
                chosenSectionPath={chosenSectionPath}
                testId={`${name}-subsections`}
              />
            </li>
          );
        })}
      </ul>
      <div className="flex justify-around items-end p-4 border-t-2 border-purple-primary">
        <HomeLink />
        <Link to="/compendium/about">About</Link>
      </div>
    </nav>
  );
};

export default CompendiumStructure;
